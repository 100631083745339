import { store } from '../store/storeMain'
import { globalFunctions } from './globalFunctions'

export const accountManager = {

  getAccountByName(accountName) {
     let acc = globalFunctions.stringify(store.state.Accounts)

    for (let i = 0; i < acc.length; i++) {
      if (acc[i].accountName === accountName) {
        return acc[i]
      }
    }
  },

  removeDeletedAccountsFromItems(items) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].objectState === 'Delete') {
        items.splice(i, 1)
      }
    }

    return items
    },
    addAmountToAccount(data) {
        //Find Account
        let account = this.getAccountByName(data.account)

        if (account.accountAmount === null || account.accountAmount === '' || account.accountAmount === undefined) {
            account.accountAmount = 0
        }

        //Add Amount to Account
        account.accountAmount = parseFloat(account.accountAmount) + parseFloat(data.amount)
        
        store.commit('updateAccountinStore', account)
    },
    subtractAmountFromAccount(data) {
        //Find Account
        let account = this.getAccountByName(data.account)
       
        if (account.accountAmount === null || account.accountAmount === '' || account.accountAmount === undefined) {
            account.accountAmount = 0
        }

        //Subtract Amount from Account
        account.accountAmount = parseFloat(account.accountAmount) - parseFloat(data.amount)

        store.commit('updateAccountinStore', account)
    },



}
