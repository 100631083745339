export const listItemFunctions = {

  lItem: {
    id: 0,
    listType: '',
    name: '',
    account: '',
    type: '',
    amount: 0,
    amountSpent: 0,
    refillAmount: 0,
    date: '',
    currency: '',

    show: false
  },

  convertItemsMinusDeletedTolItem(itemMinusDeleted, itemId, type) {
    let selectedItem = []

    for (let i = 0; i < itemMinusDeleted.length; i++) {
      if (itemMinusDeleted[i].id === itemId) {
          selectedItem = itemMinusDeleted[i]
          break
      }
    }

    return this.conversionManager(type, selectedItem)

  },

  conversionManager(type, data) {
      switch (type) {
          case "account":
              this.convertAccountTolItem(data)
              break
          //case "income":
          //  this.convertIncomeTolItem(data)
          //  break
          //case "expense":
          //  this.convertExpenseTolItem(data)
          //  break
          case "budget":
              this.convertBudgetTolItem(data)
              break
          //case "transfer":
          //  this.convertTransferTolItem(data)
          //  break
          case "transaction":
              this.convertTransactionTolItem(data)
              break
      }

    this.lItem.listType = type

    return this.lItem
  },
  convertAccountTolItem(data) {
    this.lItem.id = data.id
    this.lItem.listType = this.type
    this.lItem.name = data.accountName
    this.lItem.type = data.accountType
    this.lItem.amount = data.accountAmount
    this.lItem.currency = data.accountCurrency
  },
  //convertIncomeTolItem(data) {
  //  this.lItem.id = data.id
  //  this.lItem.name = data.incomeName
  //  this.lItem.type = data.incomeType
  //  this.lItem.amount = data.incomeAmount
  //  this.lItem.currency = data.currency
  //  this.lItem.account = data.account
  //  this.lItem.date = data.incomeDate
  //},
  //convertExpenseTolItem(data) {
  //  this.lItem.id = data.id
  //  this.lItem.name = data.expenseName
  //  this.lItem.type = data.expenseType
  //  this.lItem.amount = data.expenseAmount
  //  this.lItem.currency = data.currency
  //  this.lItem.account = data.account
  //  this.lItem.date = data.expenseDate
  //},
  convertBudgetTolItem(data) {
    this.lItem.id = data.id
    this.lItem.name = data.budgetName
    this.lItem.type = data.budgetType
    this.lItem.amount = data.budgetAmount
    this.lItem.currency = data.currency
    this.lItem.account = data.account
    this.lItem.amountSpent = data.amountSpent
  },
  //convertTransferTolItem(data) {
  //  this.lItem.id = data.id
  //  this.lItem.name = data.transferName
  //  this.lItem.amount = data.transferAmount
  //  this.lItem.currency = data.currency
  //  this.lItem.account = data.account
  //  this.lItem.date = data.transferDate
  //},
  convertTransactionTolItem(data) {
    this.lItem.id = data.id
    this.lItem.name = data.transactionName
    this.lItem.type = data.transactionType
    this.lItem.amount = data.transactionBalances[0].transactionAmount
    this.lItem.currency = data.currency
    this.lItem.account = data.account
    this.lItem.date = data.transactionBalances[0].transactionDate
  }
}
